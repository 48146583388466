<div [id]="slice.sectionId" class="container margin-on-scroll">
  <div class="my-8 lg:my-16">
    <div class="max-w-7xl mx-auto">
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        @if (slice.title) {
          <div
            data-source="prismic"
            class="text-center text-color-centered-accordion-titles"
            [innerHtml]="slice.title"
          ></div>
        }
        <dl class="mt-6 space-y-6">
          @if (slice.items && slice.items.length > 0) {
            <lib-accordion>
              @for (item of slice.items; track item; let i = $index) {
                <lib-accordion-item [index]="i" (activeIndex)="activeIndex.set($event)" [isOpen]="activeIndex() === i">
                  <div header [innerHTML]="item.title"></div>
                  <ngx-dynamic-hooks data-source="prismic" [content]="item.content"></ngx-dynamic-hooks>
                </lib-accordion-item>
              }
            </lib-accordion>
          }
        </dl>
      </div>
    </div>
  </div>
</div>
