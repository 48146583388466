import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { AccordionComponent, AccordionItemComponent } from 'accordion';
import { CenteredAccordionSlice } from './centered-accordion.slice';

@Component({
  selector: 'lib-centered-accordion',
  templateUrl: './centered-accordion.component.html',
  imports: [AccordionComponent, AccordionItemComponent, DynamicHooksComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CenteredAccordionComponent {
  @Input() slice: CenteredAccordionSlice;
  public activeIndex = signal<number>(null);
}
