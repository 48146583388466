import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model, output } from '@angular/core';

@Component({
  selector: 'lib-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  animations: [
    trigger('accordionBody', [
      transition(':enter', [
        style({ maxHeight: 0, paddingTop: 0, paddingBottom: 0 }),
        animate('.2s ease', style({ maxHeight: '500px', paddingTop: '1rem', paddingBottom: '1rem' })),
      ]),
      transition(':leave', [
        style({ maxHeight: '500px', paddingTop: '1rem', paddingBottom: '1rem' }),
        animate('.2s ease', style({ maxHeight: 0, paddingTop: 0, paddingBottom: 0 })),
      ]),
    ]),
  ],
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionItemComponent {
  public parentBackground = input<string>();
  public index = input<number>();
  public activeIndex = output<number>();
  public isOpen = model(false);
  public isToggleEnabled = input(true);

  toggle() {
    this.isOpen.set(!this.isOpen());
    this.activeIndex.emit(this.index());
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.toggle();
    }
  }
}
